import React from "react";

function Footer({ btnShow, onClick, title = "검사하기" }) {
  return (
    <footer className="footer">
      <div className="btn-wrap">
        <button type="button" className="btn-txt" onClick={onClick} disabled={!btnShow}>
          {title}
        </button>
      </div>
    </footer>
  );
}

export default React.memo(Footer);
