import React, { useEffect, useState, useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { beusable, callFinish } from "../common";
import { getKbSurveyResult } from "../api";
import Footer from "../components/Footer";
import Header from "../components/Header";
import Popup from "../components/Popup";
import Store from "../context/Store";

function Result(props) {
  const { state } = useContext(Store);
  const [popup, setPopup] = useState(false);
  const navigate = useNavigate();
  const { surveyId } = useLocation().state;

  useEffect(() => {
    beusable();
  }, []);

  const {
    isLoading,
    data: result,
    error,
  } = useQuery({
    queryKey: ["result", surveyId],
    queryFn: () => getKbSurveyResult(state, surveyId),
  });

  function Dtl({ result }) {
    const { name, content } = result;
    let color = "";
    const len = content.indexOf("\n");
    const contentTitle = content.substr(0, len);
    const contentSubTitle = content.substr(len);

    if (name === "빨간색") {
      color = "red";
    } else if (name === "파란색") {
      color = "blue";
    } else if (name === "보라색") {
      color = "purple";
    } else if (name === "연두색") {
      color = "green"; //eslint-disable-line no-unused-vars
    }
    return (
      <>
        <p className="mental-result-txt">
          <span style={{ whiteSpace: "pre-line" }} className={`color ${color}`}>
            {name}
          </span>
        </p>
        <p className="detail-title">{contentTitle}</p>
        <pre className="detail-txt">{contentSubTitle}</pre>
      </>
    );
  }

  const ResultDtl = React.memo(Dtl);

  const popShow = () => {
    setPopup(popup === false ? true : false);
  };

  const move = () => {
    navigate("/findme/question", {
      state: {
        title: result.data.survey.name,
        directive: result.data.survey.directive,
        surveyId: surveyId,
      },
    });
  };

  if (isLoading) {
    return null;
  }

  if (error) {
    return <Popup type={"alert"} msg={error.message} onClick={() => callFinish({})} />;
  }

  return (
    <>
      {result.code !== 200 ? (
        <Popup type={"alert"} msg={result.msg} />
      ) : (
        <>
          <Header userGroup={state.userGroup} title={result.data.survey.name} />
          <div className="content-wrap">
            <div className="content scrollBox">
              <div className={state.surveyId === "1" ? "mental-result" : "mental-result type02"}>
                {/* <p className="info-title"></p> */}
                <img
                  src={result.isLoading ? null : result.data.thumbnailPath}
                  className="mental-result-img"
                  alt="thumbnail"
                />
                <ResultDtl result={result.data} />
              </div>
            </div>
          </div>
          <Footer btnShow={true} onClick={popShow} title={"검사 다시하기"} />
          {popup === true ? (
            <Popup
              type={"confirm"}
              msg={"기존 검사결과가 사라져요.\n검사를 다시 진행하시겠어요?"}
              onclick={move}
              cancel={popShow}
            />
          ) : null}
        </>
      )}
    </>
  );
}

export default Result;
