// 현재 호출한 디바이스가 어떤 것인지 체크합니다.
const isMobile = {
  Android: function () {
    return navigator.userAgent.match(/Android/i) == null ? false : true;
  },
  iOS: function () {
    return navigator.userAgent.match(/iPhone|iPad|iPod/i) == null ? false : true;
  },
  any: function () {
    return isMobile.Android() || isMobile.iOS();
  },
};

// 입력 된 링크를 전달하는 Bridge 함수
function callFinish(json) {
  if (isMobile.any()) {
    if (isMobile.Android()) {
      window.Android.callFinish(JSON.stringify(json));
    } else if (isMobile.iOS()) {
      window.webkit.messageHandlers.callFinish.postMessage(json);
    }
  }
}

// 뷰저블 호출
const beusable = function (
  w = window,
  d = document,
  a = "//rum.beusable.net/load/b220419e174715u024"
) {
  w.__beusablerumclient__ = {
    load: function (src) {
      var b = d.createElement("script");
      b.src = src;
      b.async = true;
      b.type = "text/javascript";
      d.getElementsByTagName("head")[0].appendChild(b);
    },
  };
  w.__beusablerumclient__.load(a + "?url=" + encodeURIComponent(d.URL));
};

export { callFinish, beusable };
