import React, { useEffect, useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { beusable, callFinish } from "../common";
import { getKbSurvey } from "../api";
import Footer from "../components/Footer";
import Header from "../components/Header";
import Popup from "../components/Popup";
import Store from "../context/Store";

function Survey(props) {
  const { state } = useContext(Store);
  const navigate = useNavigate();
  const { surveyId, headline } = useLocation().state;

  useEffect(() => {
    beusable();
  }, []);

  const move = () => {
    navigate("/findme/question", {
      state: { title: survey.data.name, directive: survey.data.directive, surveyId: surveyId },
    });
  };

  const {
    isLoading,
    data: survey,
    error,
  } = useQuery({ queryKey: ["survey", surveyId], queryFn: () => getKbSurvey(state, surveyId) });

  if (isLoading) {
    return null;
  }

  if (error) {
    return <Popup type={"alert"} msg={error.message} onClick={() => callFinish({})} />;
  }

  return (
    <>
      {survey.code !== 200 ? (
        <Popup type={"alert"} msg={survey.msg} />
      ) : (
        <>
          <Header userGroup={state.userGroup} title={headline} />
          <div className="content-wrap">
            <div className="content scrollBox">
              <div className="mental-infomation">
                <img
                  className="mental-infomation-img"
                  src={isLoading ? null : survey.data.thumbnailPath}
                  alt="thumbnail"
                />
                <h2 className="info-title ep01">{survey.data.name}</h2>
                <div className="round-graybox">
                  <ul>
                    <li>
                      <span className="title">검사가격</span>
                      <span className="data">
                        {survey.data.price === 0 ? "무료" : survey.data.price}
                      </span>
                    </li>
                    <li>
                      <span className="title">검사시간</span>
                      <span className="data">{survey.data.timeTaken}</span>
                    </li>
                  </ul>
                </div>
                <p className="detail-title">심리검사 설명</p>
                <pre className="detail-txt">{survey.data.description}</pre>
              </div>
            </div>
          </div>
          <Footer btnShow={true} onClick={move} />
        </>
      )}
    </>
  );
}

export default Survey;
