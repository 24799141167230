import React, { useEffect, useState, useContext, useCallback } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useQuery, useMutation } from "@tanstack/react-query";
import { beusable, callFinish } from "../common";
import { getQuestion, setSurvey } from "../api";
import Footer from "../components/Footer";
import Header from "../components/Header";
import Popup from "../components/Popup";
import Store from "../context/Store";

function Detail(props) {
  const { state } = useContext(Store);
  const [btnShow, setBtnShow] = useState(false);
  const [surveyItems, setSurveyItems] = useState({});
  const navigate = useNavigate();
  const { title, directive } = useLocation().state;
  let surveyItem = [];

  useEffect(() => {
    beusable();
  }, []);

  const radioEvent = () => {
    surveyItem = [];
    for (let index = 0; index < question.data.length; index++) {
      const el = document.getElementsByName(`rd0${index + 1}`);
      for (var i = 0; i < el.length; i++) {
        if (el[i].checked) {
          surveyItem.push(el[i].value);
        }
      }
    }
    if (question.data.length === surveyItem.length) {
      setSurveyItems({
        surveyItems: surveyItem,
        surveyId: state.surveyId,
        userNo: state.userNo,
      });
      setBtnShow(true);
    }
  };

  const saveSurvey = useMutation({
    mutationFn: (surveyItems) => setSurvey(surveyItems),
    onSuccess: () => {
      navigate("/result");
    },
    onError: (error) => {
      return <Popup type={"alert"} msg={error.message} />;
    },
  });

  const savetest = useCallback(() => saveSurvey.mutate(surveyItems), [saveSurvey, surveyItems]);

  const {
    isLoading,
    data: question,
    error,
  } = useQuery({ queryKey: ["question"], queryFn: () => getQuestion(state) });

  if (isLoading) {
    return null;
  }

  if (error) {
    return <Popup type={"alert"} msg={error.message} onClick={() => callFinish({})} />;
  }

  return (
    <>
      {question.code !== 200 ? (
        <Popup type={"alert"} msg={question.msg} />
      ) : (
        <>
          <Header title={title} />
          <div className="content-wrap">
            <div className="content scrollBox">
              <div className="mental-test">
                <p className="tip-recom">{directive}</p>
                {question.data.map((data, index) => (
                  <div className="survey-box" key={index}>
                    <p className="survey-box-title">
                      <strong>{index + 1}. </strong>
                      {data.content}
                    </p>
                    <div
                      className={
                        state.surveyId === "1" || state.surveyId === "7"
                          ? "btn-survey-list"
                          : "btn-survey-radio type-col"
                      }
                    >
                      {data.item.map((item) => (
                        <p
                          className={
                            state.surveyId === "1" || state.surveyId === "7" ? "" : "ipt-rdo"
                          }
                          key={item.id}
                        >
                          <input
                            type="radio"
                            name={"rd0" + (index + 1)}
                            value={item.id}
                            id={item.id}
                            onChange={radioEvent}
                          />
                          <label htmlFor={item.id}>{item.content}</label>
                        </p>
                      ))}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <Footer btnShow={btnShow} onClick={savetest} />
        </>
      )}
    </>
  );
}

export default Detail;
