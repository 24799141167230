import React from "react";

function NotFound() {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        fontSize: 32,
        position: "absolute",
        width: "100%",
        height: "100%",
      }}
    >
      404 Not Found
    </div>
  );
}

export default NotFound;
