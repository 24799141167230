import React from "react";
import { useNavigate } from "react-router-dom";
import { CLOSE_URL } from "../api";

function Header({ userGroup, title }) {
  const navigate = useNavigate();

  return (
    <header className="header">
      <h1 className="title">{title}</h1>
      {userGroup ? (
        <>
          <button type="button" className="btn-head back" onClick={() => navigate(-1)}>
            이전화면
          </button>
          <button
            type="button"
            className="btn-head close"
            onClick={() => (window.location.href = CLOSE_URL)}
          >
            팝업닫기
          </button>
        </>
      ) : null}
    </header>
  );
}

export default React.memo(Header);
