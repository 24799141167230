import axios from "axios";

let BASE_URL = "";
switch (window.location.hostname) {
  case "dmindtest.kbhcs.co.kr": //DEV
    BASE_URL = "https://dmindtest-api.kbhcs.co.kr";
    break;
  case "qa1mindtest.kbhcs.co.kr": //QA
    BASE_URL = "https://qa1mindtest-api.kbhcs.co.kr";
    break;
  case "mindtest.kbhcs.co.kr": //PRDO
    BASE_URL = "https://mindtest-api.kbhcs.co.kr";
    break;
  default: // DEV
    BASE_URL = "https://dmindtest-api.kbhcs.co.kr";
    break;
}

export const CLOSE_URL =
  window.location.hostname === "mindtest.kbhcs.co.kr"
    ? "https://m.kbinsure.co.kr:8547/MA110030001.ecs"
    : "https://mect.kbinsure.co.kr/MA110030001.ecs";

/* Ocare MindTest */
export async function getSurvey({ surveyId }) {
  return await axios.get(`${BASE_URL}/survey/${surveyId}`).then((res) => res.data);
}

export async function getQuestion({ surveyId }) {
  return await axios.get(`${BASE_URL}/survey/${surveyId}/question`).then((res) => res.data);
}

export async function getSurveyResult({ surveyId, userNo }) {
  return await axios
    .get(`${BASE_URL}/survey/${surveyId}/result/userGroup/ocare/${userNo}`)
    .then((res) => res.data);
}

export async function setSurvey({ surveyId, userNo, surveyItems }) {
  return await axios
    .post(
      `${BASE_URL}/survey/${surveyId}/result/userGroup/ocare/${userNo}`,
      { itemIds: surveyItems },
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json;charset=utf-8",
        },
      }
    )
    .then((res) => res.data);
}

/* KBinsurance MindTest */

export async function getKbSurveyList({ token, userGroup }) {
  return await axios
    .get(`${BASE_URL}/findme/v1?token=${token}&userGroup=${userGroup}`)
    .then((res) => res.data);
}

export async function getKbSurvey({ token, userGroup }, surveyId) {
  return await axios
    .get(`${BASE_URL}/findme/v1/${surveyId}?token=${token}&userGroup=${userGroup}`)
    .then((res) => res.data);
}

export async function getKbQuestion({ token, userGroup }, surveyId) {
  return await axios
    .get(`${BASE_URL}/findme/v1/${surveyId}/question?token=${token}&userGroup=${userGroup}`)
    .then((res) => res.data);
}

export async function getKbSurveyResult({ token, userGroup }, surveyId) {
  return await axios
    .get(`${BASE_URL}/findme/v1/${surveyId}/result?token=${token}&userGroup=${userGroup}`)
    .then((res) => res.data);
}

export async function setKbSurvey({ surveyId, token, userGroup, surveyItems }) {
  return await axios
    .post(
      `${BASE_URL}/findme/v1/${surveyId}/result?token=${token}&userGroup=${userGroup}`,
      { itemIds: surveyItems },
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json;charset=utf-8",
        },
      }
    )
    .then((res) => res.data);
}
