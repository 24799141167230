import React, { useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { beusable, callFinish } from "../common";
import { getKbSurveyList, CLOSE_URL } from "../api";
import Popup from "../components/Popup";
import Store from "../context/Store";

function Main(props) {
  const { state } = useContext(Store);
  const navigate = useNavigate();

  useEffect(() => {
    beusable();
  }, []);

  const {
    isLoading,
    data: results,
    error,
  } = useQuery({ queryKey: ["surveylist"], queryFn: () => getKbSurveyList(state) });

  function Dtl({ surveyList }) {
    const isAnswered = surveyList.map((v, i) => v.isAnswered);
    const isOpend = surveyList.map((v, i) => v.isOpend);
    let answeredResult = [];

    for (let index = 0; index < surveyList.length; index++) {
      if (isOpend[index] === true) {
        if (isAnswered[index] === true) {
          if (index === 0) {
            answeredResult.push("land month12 clear");
          } else {
            answeredResult.push(`land month${index} clear`);
          }
        } else if (isAnswered[index] === false) {
          if (index === 0) {
            answeredResult.push("land month12 on");
          } else {
            answeredResult.push(`land month${index} on`);
          }
        }
      } else {
        if (index === 0) {
          answeredResult.push("land month12");
        } else {
          answeredResult.push(`land month${index}`);
        }
      }
    }

    return (
      <>
        {answeredResult.map((data, index) => (
          <div className={data} key={index}>
            <button
              type="button"
              className="btn-land"
              onClick={
                data.indexOf(" clear") > 1
                  ? () => result(surveyList[index].id, surveyList[index].headline)
                  : () => survey(surveyList[index].id, surveyList[index].headline)
              }
            >
              심리검사 이동
            </button>
          </div>
        ))}
      </>
    );
  }

  const MainDtl = React.memo(Dtl);

  const survey = (surveyId, headline) => {
    navigate("/findme/summary", {
      state: { surveyId, headline },
    });
  };

  const result = (surveyId, headline) => {
    navigate("/findme/result", {
      state: { surveyId, headline },
    });
  };

  if (isLoading) {
    return null;
  }

  if (error) {
    return <Popup type={"alert"} msg={error.message} onClick={() => callFinish({})} />;
  }

  return (
    <>
      {results.code !== 200 ? (
        <Popup type={"alert"} msg={results.msg} />
      ) : (
        <>
          <div className="content-wrap">
            <div className="content scrollBox">
              <button
                type="button"
                className="btn-pg-close"
                onClick={() => (window.location.href = CLOSE_URL)}
              ></button>
              <div className="event-box">
                <div className="sub-event">
                  <img src={require("../img/top-2.png")} alt="오, 하루의 나에게로 떠나는 여행" />
                </div>
                <div className="mental-land">
                  <p className="bgicon cloud cloud1"></p>
                  <p className="bgicon cloud cloud2"></p>
                  <p className="bgicon ship"></p>
                  <p className="bgicon ship ship2"></p>
                  <p className="bgicon ballon"></p>
                  <p className="bgicon cloud cloud3"></p>
                  <MainDtl surveyList={results.data} />
                </div>
                <img src={require("../img/notice.png")} alt="이벤트 유의사항" />
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default Main;
