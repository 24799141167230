import React from "react";

function Popup({ type, msg, onclick, cancel }) {
  return (
    <div className="popup-wrap show">
      <div className="popup-bg"></div>
      <div className="popup popup-mid">
        <div className="popup-box">
          <div className="pop-body">
            <p style={{ whiteSpace: "pre-line" }}>{msg}</p>
          </div>
          <div className="pop-foot">
            <p className="btn-wrap">
              {type === "alert" ? (
                <button type="button" className="btn-txt" onClick={onclick}>
                  확인
                </button>
              ) : (
                <>
                  <button type="button" className="btn-txt gray popup-close" onClick={cancel}>
                    취소
                  </button>
                  <button type="button" className="btn-txt" onClick={onclick}>
                    다시하기
                  </button>
                </>
              )}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default React.memo(Popup);
