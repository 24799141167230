import { useState } from "react";
import { Route, Routes, useSearchParams } from "react-router-dom";
import KbMain from "./routes/KbMain";
import KbSurvey from "./routes/KbSurvey";
import KbDetail from "./routes/KbDetail";
import KbResult from "./routes/KbResult";
import Survey from "./routes/Survey";
import Detail from "./routes/Detail";
import Result from "./routes/Result";
import NotFound from "./routes/NotFound";
import Store from "./context/Store";
import "./css/style.css";

function Router() {
  const [params] = useSearchParams();
  /* Ocare MindTest */
  const [userNo, setUserNo] = useState(params.get("userNo"));
  const [surveyId, setSurveyId] = useState(params.get("surveyId"));
  /* KBinsurance MindTest */
  const [token, setToken] = useState(encodeURIComponent(params.get("token")));
  const [userGroup, setUserGroup] = useState(params.get("userGroup"));

  const value = {
    state: { userNo, surveyId, token, userGroup },
    actions: { setUserNo, setSurveyId, setToken, setUserGroup },
  };
  return (
    <>
      <Store.Provider value={value}>
        <Routes>
          <Route path="/findme/" element={<KbMain />} />
          <Route path="/findme/summary" element={<KbSurvey />} />
          <Route path="/findme/question" element={<KbDetail />} />
          <Route path="/findme/result" element={<KbResult />} />
          <Route path="/" element={<Survey title={`Episode.0${surveyId}`} />} />
          <Route path="/detail" element={<Detail />} />
          <Route path="/result" element={<Result />} />
          <Route path="/*" element={<NotFound />} />
        </Routes>
      </Store.Provider>
    </>
  );
}

export default Router;
